footer.footer {
    background: $primary;
    color: $white;
}

.footer-block {
    color: $white;

    a {
        color: $white;
    }

    .block-title {
        color: $white;

        &.label-collapse {
            &:after {
                @include svg-icon(1.4rem, 1.4rem, $icon-minus-circle, $white);
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1.4rem, 1.4rem, $icon-plus-circle, $white);
                }
            }
        }
    }

    .block-content {
        ul {

            &.check {
                li::before {
                    @include svg-icon(1.4rem, 1.4rem, $icon-check, $white);
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .block-content {
        ul {
            &.check {
                li::before {
                    @include svg-icon(1rem, 1rem, $icon-check, $white);
                }
            }
        }
    }
}

.footer-logo {

    @include media-breakpoint-down($main-breakpoint) {
        margin-top: 3rem;
    }
}
.footer-extra-info-wrapper {
    p {
        color: $primary;
    }
}
.block-order-catalog {
    h3 {
        color: $white;
    }

    .btn-order-catalog {
        border-color: $secondary;

        &:hover {
            color: $white;
        }
    }
}

.footer-bottom-wrapper {
    padding: 2.5rem 0;

    .copyright {
        color: $white;
        font-weight: 700;
    }

    @include media-breakpoint-down(sm) {
        .copyright {
            margin-bottom: 1rem;
            text-align: center;
        }

        .block-payments {
            text-align: center;
        }
    }
}

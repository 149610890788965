.home-cta {
    display: none;
}

.hero-block .hero-links-list .slide-item .hero-title {
    color: $primary;
}

.hero-block .hero-links-list .slide-item {
    border-width: 5px;
}

.hero-block .hero-links-list .slide-item.active {
    border-color: $secondary;
}

.hero-block .hero-banner-list .hero-banner-image img,
.hero-block .hero-banner-list {
    border-radius: 0;
}

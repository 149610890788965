.navbar {
    &.bg-light {
        background-color: $primary !important;
    }

    .nav-item {
        a {
            &.nav-link {
                color: $white;
            }
        }
        &:hover {
            a {
                &.nav-link {
                    color: $primary;
                }
            }
        }

        a.nav-link {
            @include media-breakpoint-down($main-breakpoint) {
                color: $primary;
            }
        }
    }
}

$iconClasses: (
    toggler: $icon-bars,
    search: $icon-search,
    account: $icon-user,
    minicart: $icon-shopping-basket,
    wishlist: $icon-heart,
);
$iconDesktopSize: 1.4rem;
$iconMobileSize: 1.25rem;

@each $class, $icon in $iconClasses {
    .header,
    .navbar {
        &-#{$class} {
            .icon {
                @include svg-icon($iconDesktopSize, $iconDesktopSize, $icon, $white);
                vertical-align: middle;

                @include media-breakpoint-down($main-breakpoint) {
                    @include svg-icon($iconMobileSize, $iconMobileSize, $icon, $primary);
                }
            }
        }
    }
}

.navbar-icons {
    .header-wishlist,
    .header-account,
    .header-minicart {
        .counter {
            background-color: $secondary;
        }
    }

    .header-wishlist {
        display: none;
    }
}

.navbar.bg-light {
    @include media-breakpoint-down($main-breakpoint) {
        background-color: $white !important;
    }
}

.navbar-header {
    padding: 1rem 15px;
    border-bottom: 1px solid $gray-200;
}

.navbar-light .navbar-nav .nav-link:focus {
    color: $white;
}

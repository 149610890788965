.checkout-cart-index {
    .totals-tax {
        display: contents !important;
    }
}
.checkout-cart-index .cart-container .action.update {
    display: block;
}

.checkout-cart-index .cart-container .cart .item-info .spinbox {
    display: block;
}

.offcanvas-minicart {
    .offcanvas-body {

        .product-item {
            .price-container {
                margin: 0 0;
                float: right !important;
            }

            .details-qty {
                margin: 1rem 0;
                display: initial;
            }
        }
    }
}
.checkout-index-index fieldset.street>.control {
    display: block;

    .field {
        max-width: none;
        flex: none;

        &.additional .label {
            display: none;
        }
    }
}

.box-tocart {
    .fieldset {
        display: flex;
        align-items: center;
    }
}
.product-add-form {
    .spinbox {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }
    .form-group.qty, .qty.field {
        display: block;

        label {
            display: none;
        }
    }
    .spinbox-input {
        max-width: 4rem;
        text-align: center;
    }
    .btn-spinbox {
        color: $body-color;
    }
    .action.towishlist {
        display: none;
    }
}

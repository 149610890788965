// // Global theme variables
// $margin-x:                              ($spacer * 2); // Left & Right
// $margin-y:                              ($spacer * 2); // Top & Bottom
// $padding-x:                             ($spacer * 2); // Left & Right
// $padding-y:                             ($spacer * 2); // Top & Bottom

// $main-breakpoint:                       md;
// $font-family-icon:                      'Font Awesome 5 Pro';
// $font-weight-icon:                      400;


// Breadcrumbs
// $breadcrumb-height:                     3rem;


// // Swatches
// $swatch-padding-y:                      .25rem;
// $swatch-padding-x:                      .4rem;
// $swatch-width:                          ($spacer * 2.5);
// $swatch-height:                         ($spacer * 2);
// $swatch-image-width:                    8.125rem;
// $swatch-image-height:                   8.125rem;
// $swatch-font-size:                      $font-size-base;


// // Slick gallery
// $photo-hover-cursor:                    zoom-in;
// $photo-loader-background:               $white;
// $photo-slider-arrow-bg:                 transparent;
$photo-slider-arrow-color:              $primary;
$photo-slider-arrow-size:               3rem;

// $photo-thumbs-arrow-bg:                 transparent;
// $photo-thumbs-arrow-color:              $primary;
// $photo-thumbs-arrow-size:               3.5rem;

// $slick-dots-background:                 $gray-200;
// $slick-dots-background-active:          $primary;
// $slick-dots-width:                      12px;
// $slick-dots-height:                     12px;
// $slick-dots-border-radius:              50%;

// $player-button-width:                   4rem;
// $player-button-height:                  4rem;
// $player-button-background:              $primary;
// $player-button-border-radius:           50%;
// $player-button-color:                   $white;

// $photo-lightbox-max-width:              1200px;
// $photo-lightbox-max-height:             85vh;
// $photo-lightbox-background:             rgba(0, 0, 0, 0.3);
// $photo-lightbox-arrow-bg:               transparent;
// $photo-lightbox-arrow-color:            $white;
// $photo-lightbox-arrow-size:             2rem;
// $photo-lightbox-close-bg:               transparent;
// $photo-lightbox-close-color:            $white;
// $photo-lightbox-close-size:             2.5rem;


// // Product
// $spinbox-width:                         ($spacer * 4) ;


// // Offcanvas
// $zindex-offcanvas:                      1070;
// $zindex-offcanvas-backdrop:             1060;

// $offcanvas-heading-bg:                  $primary;
// $offcanvas-heading-color:               $white;
// $offcanvas-bg:                          $white;
// $offcanvas-backdrop-bg:                 $black;
// $offcanvas-backdrop-opacity:            .5;

// $offcanvas-max-width:                   25rem;
// $offcanvas-font-size:                   $h5-font-size;

// $offcanvas-fade-transform:              translateX(-100%);
// $offcanvas-show-transform:              none;
// $offcanvas-transition:                  transform .2s ease-out;


// // Buttons
// $btn-primary:                           $primary;
// $btn-secondary:                         $secondary;
// $btn-search:                            $primary;
$btn-addtocart:                         $secondary;
$btn-cta:                               $secondary;
// $btn-wishlist:                          $light;
// $btn-compare:                           $light;
// $btn-login:                             $primary;
// $btn-spinbox:                           $input-border-color;
// $btn-back:                              $gray-600;


// Icons
// $icon-minicart-navbar:                  $icon-minicart;
// $icon-account-navbar:                   $icon-account;
// $icon-wishlist-navbar:                  $icon-wishlist;
// $icon-search-navbar:                    $icon-search;


// // Review
// $review-icon-color:                     $yellow;
